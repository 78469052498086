/* src/index.css */
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/700.css";
@import "@fontsource/merriweather/400.css";
@import "@fontsource/merriweather/700.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  @apply bg-background text-text font-sans;
}

.dark body {
  @apply bg-darkBg text-darkText;
}
/* src/index.css */

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari, Edge) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
